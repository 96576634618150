import React from 'react';
import ContactTemplate from './../../templates/ContactPageTemplate';

const Contact = () => {
  return (
    <>
      <ContactTemplate lang={'de'} />
    </>
  );
};

export default Contact;
